import { ClientEvents } from 'common/events/ClientEvents';
import React, { useRef } from 'react';
import { useZipCodeInput } from 'src/rebrand/useZipCodeInput';
import { Location } from 'src/shared/design-system/Icon';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { useTracker } from 'src/shared/hooks/useTracker';
import { StyledModal } from 'web-common/src/shared/design-system/components/Modal';

interface ZipCodeSelectionModalProps {
  isShowing: boolean;
  isMealPlan?: boolean;
  redirectUrl?: string;
  redirectOnSubmit?: boolean;
  hide: () => void;
}

export const ZipCodeSelectionModal: React.FC<ZipCodeSelectionModalProps> = ({
  isShowing,
  redirectUrl,
  redirectOnSubmit,
  isMealPlan,
  hide,
}) => {
  const tracker = useTracker();
  const inputRef = useRef<HTMLInputElement>(null);

  const onZipSubmit = (zip: string) => {
    tracker.track(ClientEvents.EXPLORE_ZIP_SUBMIT, { zip });
    hide();
  };
  const { onChange, onSubmit, zipCode, cachedZipCode } = useZipCodeInput({
    redirectRoute: redirectUrl,
    isMealPlan,
    onZipSubmit,
    redirectOnSubmit,
  });

  const headerText = cachedZipCode ? `Update your zip code` : `Enter your zip code`;

  useOnMount(() => inputRef.current?.focus());

  return (
    <StyledModal
      headerText={headerText}
      isShowing={isShowing}
      primaryButtonText='Update'
      primaryDisabled={zipCode.length < 5}
      showCloseButton={false}
      onAccept={onSubmit}
      onClose={cachedZipCode ? hide : undefined}>
      <div className='flex justify-center'>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit();
          }}
          className='flex w-full max-w-[300px] items-center gap-2 rounded-xl border border-solid border-neutral-200 p-2 text-neutral-400'>
          <Location width='24px' height='24px' className='-mt-1 text-current' />
          <input
            className='m-0 max-w-[400px] p-0 text-xl leading-none text-neutral-800 outline-none focus:outline-none active:outline-none'
            inputMode='numeric'
            placeholder='Zip code'
            ref={inputRef}
            type='number'
            value={zipCode}
            onChange={(e) => onChange(e.target.value)}
          />
        </form>
      </div>
    </StyledModal>
  );
};
