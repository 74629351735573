import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import { MealPlanPreferences, useGetSubscriptionQuery } from 'src/gqlReactTypings.generated.d';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';

interface IProps {
  userHasActiveMealPlan: boolean;
  mealPlanDataLoaded: boolean;
  mealPlan: Pick<MealPlanPreferences, 'id' | 'nextDeliveryDate' | 'numMeals' | 'numServings' | 'format'> | null;
  mealPlanRegionId?: number | null;
  mealPlanZipCode?: string | null;
  refetchMealPlanData: () => void;
}

export const MealPlanSubscriptionContext = React.createContext<IProps>({
  userHasActiveMealPlan: false,
  mealPlanDataLoaded: false,
  mealPlan: null,
  refetchMealPlanData: () => {},
});

export const MealPlanSubscriptionProvider: React.FC = ({ children }) => {
  const [currentUser, currentUserLoading] = useCurrentUser();

  const {
    data: subscriptionData,
    loading,
    called,
    refetch,
  } = useGetSubscriptionQuery({
    variables: {
      userId: currentUser?.id ?? '',
    },
    skip: !currentUser?.id,
  });

  const { mealPlan, mealPlanRegionId, mealPlanZipCode, nextDeliveryDate } = subscriptionData?.getMealPlanForUser ?? {};

  const value = useMemo((): IProps => {
    const { mealPlanType } = mealPlan ?? {};
    const { unit: numMeals, numServings, format } = mealPlanType ?? {};
    return {
      userHasActiveMealPlan: !isNil(mealPlan) && called && !loading,
      mealPlanDataLoaded: (!currentUser && !currentUserLoading) || (!loading && called),
      mealPlan:
        mealPlan?.mealPlanType && mealPlan?.id && numMeals && numServings && nextDeliveryDate
          ? { id: mealPlan.id, numMeals, numServings, nextDeliveryDate, format }
          : null,
      refetchMealPlanData: refetch,
      mealPlanRegionId,
      mealPlanZipCode,
    };
  }, [mealPlan, loading, called, currentUser, currentUserLoading, refetch, mealPlanRegionId, mealPlanZipCode]);

  return <MealPlanSubscriptionContext.Provider value={value}>{children}</MealPlanSubscriptionContext.Provider>;
};
